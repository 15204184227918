<script>


/**
 * Landlord GST Setup component
 */


 import { required, } from "vuelidate/lib/validators";
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'


import { financial } from '@/api/misc'
import { getPMApi } from '@/api/pm'
import { autoComplete } from '@/api/misc'

export default {

    emits: ['confirm', 'cancel'],

    props: {
        property : {
            type : Object,
            default: ()=> {

            }
        }
    },
    components: {
        VueBootstrapTypeahead
    },

    validations: {
        remit_pm_id: { required },
        receive_pm_id: { required },
    },
 

    data() {
      
        return {
            submitted : false,
            remit_pm_id : '',
            receive_pm_id : '',
            remit_pm : {},
            receive_pm : {},
            searched_pm_data : [],

            transfered_rent_in_hold   : 0,
            transfered_owner_reserved : 0,
            transfered_deposit        : 0,
            transfered_balance        : 0,
            
            remit_rent_in_hold        : 0,
            receive_rent_in_hold      : 0,

            remit_owner_reserved      : 0,
            receive_owner_reserved    : 0,

            remit_deposit             : 0,
            receive_deposit           : 0,

            remit_balance             : 0,
            receive_balance           : 0,
            note                      : '',

        }
       
    },
    methods: {

        f(v, d=2) {
            return financial(v, d)
        },

        formSubmit() {
            this.$v.$touch()
            
            if (this.$v.$invalid == false) {
                let data = {
                    remit_pm_id            :  this.remit_pm_id,
                    receive_pm_id          :  this.receive_pm_id,
                    transfer_balance       :  this.transfered_balance,
                    transfer_owner_reserve :  this.transfered_owner_reserved,
                    transfer_rent_in_hold  :  this.transfered_rent_in_hold,
                    transfer_deposit       :  this.transfered_deposit,
                    note                   :  this.note
                }
                getPMApi().transfer_rta_balance(data).then(res =>{
                    if (res.errCode == 0) {
                        this.$emit('confirm')
                    } else {
                        this.$alertify.error("Transfer Balance  failed " + res.errCode);
                    }
                })
               
            }
            
        },

        onPMSelected(evt) {
            let pm = this.searched_pm_data.find(e=> e.property_id == evt.str_id)
            this.remit_pm             = pm
            this.remit_pm_id          = evt.str_id
            this.remit_rent_in_hold   = pm.rent_in_hold
            this.remit_owner_reserved = pm.owner_reserved
            this.remit_balance        = pm.balance_available
            this.remit_deposit        = pm.deposit
        },

        onReceivePMSelected(evt) {
            let pm = this.searched_pm_data.find(e=> e.property_id == evt.str_id)
            this.receive_pm             = pm
            this.receive_pm_id          = evt.str_id
            this.receive_rent_in_hold   = pm.rent_in_hold
            this.receive_owner_reserved = pm.owner_reserved
            this.receive_deposit        = pm.deposit
            this.receive_balance        = pm.balance_available
        },

        onSearchPM(str) {
            autoComplete({ types: ['PM'], str: str, selection: ['property_id', 'o_code', 'rent_in_hold','owner_reserved', 'deposit', 'balance_available'] }).then(res => {
                if (res.errCode == 0) {
                this.searched_pm_data = []
                res.data.map(e => {
                    this.searched_pm_data.push(e)
                })
                }
            })
        },
    },

    created() {
       
    },

    mounted() {
       
    
    },

    watch: {
        remit_pm_id(new_pm_str) {
            this.onSearchPM(new_pm_str)
        },

        receive_pm_id(new_pm_str) {
            this.onSearchPM(new_pm_str)
        },

        transfered_rent_in_hold(new_val) {
            if (this.receive_pm && this.remit_pm) {
                if (Number(new_val) > this.remit_pm.rent_in_hold ) {
                    new_val = this.remit_pm.rent_in_hold
                    this.transfered_rent_in_hold = new_val
                }
                this.remit_rent_in_hold    = financial(this.remit_pm.rent_in_hold - Number(new_val), 2)
                this.receive_rent_in_hold  = financial(this.receive_pm.rent_in_hold + Number(new_val), 2)
            }
        },

        transfered_owner_reserved(new_val) {
            if (this.receive_pm && this.remit_pm) {
                if (Number(new_val) > Number(this.remit_pm.owner_reserved) ) {
                    new_val = this.remit_pm.owner_reserved
                }
                this.remit_owner_reserved   =  financial(this.remit_pm.owner_reserved - Number(new_val) ,2)
                this.receive_owner_reserved =  financial(this.receive_pm.owner_reserved + Number(new_val) ,2)
            }
        },

        transfered_deposit(new_val) {
            if (this.receive_pm && this.remit_pm) {
                if (Number(new_val) > Number(this.remit_pm.deposit) ) {
                    new_val = this.remit_pm.deposit
                }
                this.remit_deposit   =  financial(this.remit_pm.deposit - Number(new_val) ,2)
                this.receive_deposit =  financial(this.receive_pm.deposit + Number(new_val) ,2)
            }
        },

        transfered_balance(new_val) {
            if (this.receive_pm && this.remit_pm) {
                if (Number(new_val) > Number(this.remit_pm.balance_available) ) {
                    new_val = this.remit_pm.balance_available
                }
                this.remit_balance   =  financial(this.remit_pm.balance_available - Number(new_val) ,2)
                this.receive_balance =  financial(this.receive_pm.balance_available + Number(new_val) ,2)
            }
        }



    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">

                <div class="row">
                    
                        <div class="col-lg-4">
                          <div class="mb-3">
                            <label class="form-label" for="gen-info-name-input">Remit Property</label>
                            <vue-bootstrap-typeahead ref="property" v-model="remit_pm_id" :value="remit_pm_id"
                              :data="searched_pm_data" :inputClass="{ 'is-invalid': $v.remit_pm_id.$error }"
                              :serializer="s => s.str_id" :foramterDisplay="s => s.str_id" @hit="onPMSelected($event)"
                              autocomplete="off" />
                          </div>
                        </div>

                        <div class="col-lg-4">
                          <div class="mb-3">
                            <label class="form-label" for="gen-info-name-input">Receive Property</label>
                            <vue-bootstrap-typeahead ref="property" v-model="receive_pm_id" :value="receive_pm_id"
                              :data="searched_pm_data" :inputClass="{ 'is-invalid': $v.receive_pm_id.$error }"
                              :serializer="s => s.str_id" :foramterDisplay="s => s.str_id" @hit="onReceivePMSelected($event)"
                              autocomplete="off" />
                          </div>
                        </div>
                    
                </div>

                <div class="row">
                    <div class="col-md-8">
                        <div class="mb-3">
                            <label >Note</label>
                            <input class="form-control" v-model="note" />
                        
                        </div>
                    </div>

                </div>


                <div class="row">

                    <div class="col-md-3">
                        <div class="mb-3">
                            <label >Balance Available</label>
                            <input class="form-control" v-model="remit_balance" readonly/>
                        
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="mb-3">
                            <label >Transfered-></label>
                            <input class="form-control" v-model="transfered_balance" />
                        
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="mb-3">
                            <label >Balance Available</label>
                            <input class="form-control" v-model="receive_balance" readonly />
                        
                        </div>
                    </div>
                    </div>
                    <!-- end row-->
                    
                <div class="row">

                    <div class="col-md-3">
                        <div class="mb-3">
                            <label >Rent In Hold</label>
                            <input class="form-control" v-model="remit_rent_in_hold" readonly/>
                          
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="mb-3">
                            <label >Transfered-></label>
                            <input class="form-control" v-model="transfered_rent_in_hold" />
                          
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="mb-3">
                            <label >Rent In Hold</label>
                            <input class="form-control" v-model="receive_rent_in_hold" readonly />
                          
                        </div>
                    </div>
                </div>
                <!-- end row-->

                <div class="row">

                    <div class="col-md-3">
                        <div class="mb-3">
                            <label >Deposit</label>
                            <input class="form-control" v-model="remit_deposit" readonly/>
                        
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="mb-3">
                            <label >Transfered-></label>
                            <input class="form-control" v-model="transfered_deposit" />
                        
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="mb-3">
                            <label >Deposit</label>
                            <input class="form-control" v-model="receive_deposit" readonly />
                        
                        </div>
                    </div>
                </div>
                <!-- end row-->


                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button  variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>
                    
                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>